import { Option } from '@/components/ui/multiple-selector';

import { GenericStateCreator } from '../types';

interface FiltersState {
  selectedTypes: Option[];
  selectedAccounts: Option[];
  selectedCards: Option[];
  selectedCurrencies: Option[];
  activeFilters: string[];
  openSelector: string | null;
}

interface FiltersActions {
  setSelectedTypes: (types: Option[]) => void;
  setSelectedAccounts: (accounts: Option[]) => void;
  setSelectedCards: (cards: Option[]) => void;
  setSelectedCurrencies: (currencies: Option[]) => void;
  setActiveFilters: (filters: string[]) => void;
  resetFilters: () => void;
  setOpenSelector: (selectorId: string | null) => void;
}

export type FiltersSlice = FiltersState & FiltersActions;

const initialState: FiltersState = {
  selectedTypes: [],
  selectedAccounts: [],
  selectedCards: [],
  selectedCurrencies: [],
  activeFilters: [],
  openSelector: null,
};

export const createFiltersSlice: GenericStateCreator<FiltersSlice> = (set) => ({
  ...initialState,
  setSelectedTypes: (types) =>
    set((state) => {
      state.selectedTypes = types;
    }),
  setSelectedAccounts: (accounts) =>
    set((state) => {
      state.selectedAccounts = accounts;
    }),
  setSelectedCards: (cards) =>
    set((state) => {
      state.selectedCards = cards;
    }),
  setSelectedCurrencies: (currencies) =>
    set((state) => {
      state.selectedCurrencies = currencies;
    }),
  setActiveFilters: (filters) =>
    set((state) => {
      state.activeFilters = filters;
    }),
  resetFilters: () => set(initialState),
  setOpenSelector: (selectorId) =>
    set((state) => {
      state.openSelector = selectorId;
    }),
});
