import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createCurrencySlice, CurrencySlice } from './slices/currency';
import { createFiltersSlice, FiltersSlice } from './slices/filters';
import {
  createCardSliderSlice,
  createNavbarDesktopSlice,
  createNavbarSlice,
  NavbarCardSliderSlice,
  NavbarDesktopSlice,
  NavbarSlice,
} from './slices/navbar';
import {
  createNotificationSlice,
  NotificationSlice,
} from './slices/notification';
import { createSupportSlice, SupportSlice } from './slices/support';
import { createToastSlice, ToastSlice } from './slices/toast';

type Store = CurrencySlice &
  SupportSlice &
  NavbarSlice &
  NavbarDesktopSlice &
  NavbarCardSliderSlice &
  ToastSlice &
  NotificationSlice &
  FiltersSlice;

export const useStore = create<Store>()(
  devtools(
    immer(
      persist(
        (...args) => ({
          ...createCurrencySlice(...args),
          ...createSupportSlice(...args),
          ...createNavbarSlice(...args),
          ...createNavbarDesktopSlice(...args),
          ...createCardSliderSlice(...args),
          ...createToastSlice(...args),
          ...createNotificationSlice(...args),
          ...createFiltersSlice(...args),
        }),
        {
          name: 'gcard-store',
          partialize: (state) => ({
            toasts: state.toasts,
            userToasts: state.userToasts,
            pushPermission: state.pushPermission,
            filters: {
              selectedTypes: state.selectedTypes,
              selectedAccounts: state.selectedAccounts,
              selectedCards: state.selectedCards,
              selectedCurrencies: state.selectedCurrencies,
              activeFilters: state.activeFilters,
            },
          }),
        },
      ),
    ),
  ),
);
