import { ComponentProps, lazy, Suspense } from 'react';

const StatementModalLazy = lazy(() => import('./StatementModal'));

export const StatementModal = (
  props: ComponentProps<typeof StatementModalLazy>,
) => {
  return (
    <Suspense fallback={<div />}>
      <StatementModalLazy {...props} />
    </Suspense>
  );
};
